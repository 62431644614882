import React from "react";
import { IoArrowUp } from "react-icons/io5";
import Swal from "sweetalert2";

const EventPostponed = ({ component }) => {
  const showAlert = () => {
    Swal.fire({
      title: "Hi,",
      html: `Due to unforeseen circumstances, we regret to inform you that this event has been postponed.<br/><br/>

      Our goal remains to provide you with a valuable and enriching experience, and we are committed to ensuring that the Career Fair meets your expectations. <br/><br/>
      
      We look forward to welcoming you to the rescheduled event.<br/><br/>
      
      Thank you for your understanding.`,
      icon: "info",
    });
  };

  return (
    <>
      <button
        onClick={showAlert}
        className={
          component == "partner"
            ? "btn btn-nlo-partner py-3"
            : "btn-register w-100"
        }
        type="button"
      >
        <IoArrowUp className="rotate-90" /> Register
      </button>
    </>
  );
};

export default EventPostponed;
